import { render, staticRenderFns } from "./detailed_list.vue?vue&type=template&id=2d2bb9a8&scoped=true&"
import script from "./detailed_list.vue?vue&type=script&lang=js&"
export * from "./detailed_list.vue?vue&type=script&lang=js&"
import style0 from "./detailed_list.vue?vue&type=style&index=0&id=2d2bb9a8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2bb9a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d2bb9a8')) {
      api.createRecord('2d2bb9a8', component.options)
    } else {
      api.reload('2d2bb9a8', component.options)
    }
    module.hot.accept("./detailed_list.vue?vue&type=template&id=2d2bb9a8&scoped=true&", function () {
      api.rerender('2d2bb9a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/tpm/components/detailed_list/detailed_list.vue"
export default component.exports